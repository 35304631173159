import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class ContentPagesService {
  constructor(private http: HttpClient, private alertService: AlertService) {}

  getAllInfoPages(refreshPage?: boolean): Observable<any> {
    let options;
    if (refreshPage) {
      options = {
        params: {
          refreshReq: refreshPage,
        }
      };
    }
    return this.http.get(environment.rsEndpoint + '/getPages', options).pipe(
      catchError((error) => {
        this.alertService.presentErrorAlert(error);
        return throwError(error);
      })
    );
  }

  getAllVacancies(id: number, refreshPage?: boolean): Observable<any> {
    const options: {params?: {page_id?: number; refreshReq?: boolean}} = {
      params: { page_id: id }
    };
    if (refreshPage) {
      options.params.refreshReq = refreshPage;
    }
    return this.http.get(environment.rsEndpoint + '/getVacancies', options).pipe(
      catchError((error) => {
        this.alertService.presentErrorAlert(error);
        return throwError(error);
      })
    );
  }

  getVacanciesById(id: string | number, refreshPage?: boolean): Observable<any> {
    const options: {params?: {id?: string | number; refreshReq?: boolean}} = {
      params: { id }
    };
    if (refreshPage) {
      options.params.refreshReq = refreshPage;
    }
    return this.http.get(environment.rsEndpoint + `/getVacancies`, options).pipe(
      catchError((error) => {
        this.alertService.presentErrorAlert(error);
        return throwError(error);
      })
    );
  }

  getContentDataById(id: number, refreshPage?: boolean): Observable<any> {
    const options: {params?: {id?: number; refreshReq?: boolean}} = {
      params: { id }
    };
    if (refreshPage) {
      options.params.refreshReq = refreshPage;
    }
    return this.http.get(environment.rsEndpoint + `/getPages`, options).pipe(
      catchError((error) => {
        this.alertService.presentErrorAlert(error);
        return throwError(error);
      })
    );
  }

  getContentPolicyById(id: string | number, refreshPage?: boolean): Observable<any> {
    let options;
    if (refreshPage) {
      options = {
        params: {
          refreshReq: refreshPage,
        }
      };
    }
    return this.http.get(environment.wpv2Endpoint + `/pages/${id}`, options).pipe(
      catchError((error) => {
        this.alertService.presentErrorAlert(error);
        return throwError(error);
      })
    );
  }
}
