import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from 'src/app/core/services/alert.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class PromotionsService {

  constructor(private http: HttpClient, private alertService: AlertService) { }

  getAllPromotions(page: string | number, refreshPage?: boolean): Observable<any> {
    const options: {params?: {page?: string | number; refreshReq?: boolean}} = {
      params: { page }
    };
    if (refreshPage) {
      options.params.refreshReq = refreshPage;
    }
    return this.http.get(environment.rsEndpoint + '/getPromotions', options).pipe(
      catchError((error) => {
        this.alertService.presentErrorAlert(error);
        return throwError(error);
      })
    );
  }

  getPromotionsById(id: string | number, refreshPage?: boolean): Observable<any> {
    const options: {params?: {id?: string | number; refreshReq?: boolean}} = {
      params: { id }
    };
    if (refreshPage) {
      options.params.refreshReq = refreshPage;
    }
    return this.http.get(environment.rsEndpoint + `/getPromotions`, options).pipe(
      catchError((error) => {
        this.alertService.presentErrorAlert(error);
        return throwError(error);
      })
    );
  }
}
